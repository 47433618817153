const CORE_6776_MAP = {
  ctrl: 'National Average Price',
  chal1: 'National Average Price Paid',
  chal2: 'National Market Price',
};

export function getEspLabel({ totalMsrp, suggestedPrice, nationalBasePriceTmv, core6776Recipe = 'ctrl' }) {
  if (nationalBasePriceTmv) {
    return CORE_6776_MAP[core6776Recipe];
  }

  if (!suggestedPrice) {
    return totalMsrp ? 'Total MSRP' : 'Base MSRP';
  }

  return suggestedPrice > totalMsrp ? 'What others are paying' : 'Edmunds suggests you pay';
}

export function getSuggestedDisplayPrice({ nationalBasePriceTmv, suggestedPrice, totalMsrp, baseMsrp }) {
  return nationalBasePriceTmv || suggestedPrice || totalMsrp || baseMsrp;
}
