import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorEntities } from 'client/data/models/visitor';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';

export function MakeModelLocationDefault({
  children,
  makeModelSubmodelYear,
  params,
  visitorLocation,
  isHighlights,
  disableTrademarkCharacter,
  isInternational,
}) {
  const displayVehicle = buildDisplayVehicle({ makeModelSubmodelYear, params, disableTrademarkCharacter });
  const city = get(visitorLocation, 'city', '');
  const stateCode = get(visitorLocation, 'stateCode', '');

  let locationText;
  switch (true) {
    case isHighlights: {
      locationText = `${city}, ${stateCode}`;
      break;
    }
    case isInternational: {
      locationText = `National average sale prices for the ${displayVehicle}`;
      break;
    }
    default: {
      locationText = `${displayVehicle} pricing in ${city}, ${stateCode}`;
    }
  }

  return (
    <Fragment>
      {children}
      <div className="text-gray-darker fw-normal text-start size-16">
        {locationText}
        <ZipWithDefaultActionHandling
          buttonClassName="size-16 color-primary-darker fw-normal text-transform-none"
          buttonText={isInternational ? 'Enter zip for local pricing' : undefined}
        />
      </div>
    </Fragment>
  );
}

MakeModelLocationDefault.propTypes = {
  children: PropTypes.node,
  params: CorePageParams.isRequired,
  makeModelSubmodelYear: VehicleEntities.MakeModelSubmodelYear,
  visitorLocation: VisitorEntities.Location,
  isHighlights: PropTypes.bool,
  disableTrademarkCharacter: PropTypes.bool,
  isInternational: PropTypes.bool,
};

MakeModelLocationDefault.defaultProps = {
  children: null,
  makeModelSubmodelYear: null,
  visitorLocation: {},
  isHighlights: false,
  disableTrademarkCharacter: false,
  isInternational: false,
};
