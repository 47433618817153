import React from 'react';
import PropTypes from 'prop-types';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { generateNewFlatUrl } from 'site-modules/shared/utils/srp-link-constructor';
import { TrimPricingBars } from 'site-modules/shared/components/core-page/trim-pricing-bars/trim-pricing-bars';
import { PricingCta } from './pricing-cta/pricing-cta';

import './pricing-module.scss';

export function PricingModule({ params, vehicle, isInternational }) {
  return (
    <div className="pricing-module">
      <TrimPricingBars className="mb-1_5" params={params} vehicle={vehicle} isInternational={isInternational} />
      <div className="text-center">
        <PricingCta
          vehicle={vehicle}
          buildAndPriceLink={getBuildAndPriceLink(params)}
          srpLink={generateNewFlatUrl(params)}
        />
      </div>
    </div>
  );
}

PricingModule.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  isInternational: PropTypes.bool,
};

PricingModule.defaultProps = {
  isInternational: false,
};
