import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorEntities } from 'client/data/models/visitor';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { MakeModelLocationDefault } from './make-model-location-default';

const SAVINGS_DISPLAY_THRESHOLD = 250;

export function PricingHeaderUI({
  makeModelLocationComponent: MakeModelLocationComponent,
  params,
  vehicle,
  className,
  visitorLocation,
  noPricingData,
  estimatedSavingsTrim,
  onMakeModelChange,
  creativeId,
  disableTrademarkCharacter,
  isHighlights,
  isInternational,
}) {
  let headerFragment;
  switch (true) {
    case isHighlights: {
      headerFragment = `${buildDisplayVehicle({
        makeModelSubmodelYear: vehicle,
        params,
        disableTrademarkCharacter,
      })} Trim Pricing`;
      break;
    }
    case noPricingData: {
      headerFragment = 'Pricing';
      break;
    }
    case estimatedSavingsTrim?.estimatedSavings >= SAVINGS_DISPLAY_THRESHOLD: {
      headerFragment = (
        <Fragment>
          Save as much as {getPriceString(estimatedSavingsTrim.estimatedSavings)}
          &nbsp;with Edmunds{' '}
          <TooltipItem
            className="top-0"
            id={`${creativeId}-pricing-carousel-header-tooltip`}
            item={{
              text: `Click on the trim you’re interested in to see what Edmunds suggests you should pay to get a good deal. Savings vary by trim; ${getPriceString(
                estimatedSavingsTrim.estimatedSavings
              )} refers to estimated savings off of MSRP for the ${estimatedSavingsTrim.trim} trim.`,
              placement: 'bottom',
            }}
          />
        </Fragment>
      );
      break;
    }
    default: {
      headerFragment = 'Compare dealer price quotes';
    }
  }

  return (
    <div className={classnames('pricing-header', className)}>
      <MakeModelLocationComponent
        makeModelSubmodelYear={vehicle}
        params={params}
        visitorLocation={visitorLocation}
        onMakeModelChange={onMakeModelChange}
        disableTrademarkCharacter={disableTrademarkCharacter}
        isHighlights={isHighlights}
        isInternational={isInternational}
      >
        <h2 className="heading-3 text-start mb-0_5">{headerFragment}</h2>
      </MakeModelLocationComponent>
    </div>
  );
}

PricingHeaderUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  creativeId: PropTypes.string.isRequired,
  className: PropTypes.string,
  makeModelLocationComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  visitorLocation: VisitorEntities.Location,
  noPricingData: PropTypes.bool,
  estimatedSavingsTrim: PropTypes.shape({
    trim: PropTypes.string,
    estimatedSavings: PropTypes.number,
  }),
  onMakeModelChange: PropTypes.func,
  disableTrademarkCharacter: PropTypes.bool,
  isHighlights: PropTypes.bool,
  isInternational: PropTypes.bool,
};

PricingHeaderUI.defaultProps = {
  className: null,
  makeModelLocationComponent: MakeModelLocationDefault,
  visitorLocation: null,
  noPricingData: false,
  estimatedSavingsTrim: null,
  onMakeModelChange: noop,
  disableTrademarkCharacter: true,
  isHighlights: false,
  isInternational: false,
};

export const PricingHeader = React.memo(PricingHeaderUI);
